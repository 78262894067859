import React, { useState, useEffect } from "react"
import { PageProps, Link, navigateTo } from "gatsby"

import { getQueryStringParam, isClient } from "../lib/utils"
import { SEO, Layout, Button, LoadingIndicatorPage } from "../components"
import { useSubscribeState } from "../features/Subscribe/state"
import { useAuthState } from "../features/Auth/state"

const UnsubscribePage = ({ location }: PageProps) => {
  const {
    emailSubscribeState: { isUnsubscribing, hasUnsubscribed, onUnsubscribe },
  } = useSubscribeState()
  const [token, setToken] = useState("")
  const { initialising, emailAddress } = useAuthState()
  const [isConfirmed, setIsConfirmed] = useState(false)

  useEffect(() => {
    if (isClient()) {
      const token = getQueryStringParam(location, "token")
      if (token) {
        setToken(token)
      }
    }
  }, [location, setToken])

  return (
    <Layout>
      <SEO title="Unsubscribe from updates" />
      {token ? (
        <>
          {initialising ? (
            <LoadingIndicatorPage />
          ) : (
            <div style={{ maxWidth: "500px", margin: "0 auto" }}>
              <h2>Sorry to see you leave.</h2>
              {hasUnsubscribed ? (
                <>
                  <div>
                    <small>
                      You will no longer receive emails at {emailAddress} from
                      Tipsta.
                    </small>
                  </div>
                  <p>
                    If you have the time, it would be great if you could let us
                    know why you decided to unsubscribe, by emailing us at{" "}
                    <a href="mailto:feedback@tipsta.co.uk">
                      feedback@tipsta.co.uk
                    </a>
                    . We would love to hear from you!
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Please confirm that you wish to unsubscribe and press the
                    button below in order to complete unsubscribing from Tipsta
                  </p>
                  <label>
                    <input
                      type="checkbox"
                      checked={isConfirmed}
                      onChange={e => setIsConfirmed(e.target.checked)}
                    />
                    <span>
                      I no longer wish to receive email updates from Tipsta.
                    </span>
                  </label>
                  <div>
                    <Button
                      onClick={() => onUnsubscribe(token)}
                      primary
                      submitting={isUnsubscribing}
                      disabled={!isConfirmed || isUnsubscribing}
                    >
                      Unsubscribe
                    </Button>
                  </div>
                  <div>
                    <small>Email: {emailAddress}</small>
                  </div>
                </>
              )}
              <div>
                <Link to="/">Back to Homepage</Link>
              </div>
            </div>
          )}
        </>
      ) : (
        <p>This link seems to be broken.</p>
      )}
    </Layout>
  )
}

export default UnsubscribePage
